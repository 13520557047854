import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Auth0UserService } from 'src/app/services/auth0UserService.service';

@Component({
    selector: 'app-session-management',
    templateUrl: './session-management.component.html'
})
export class SessionManagementComponent implements OnInit, OnDestroy {

    private interval: any;
    private intervalClearBlock: any;
    public disp: string = "";
    private readonly timeToWarn: number = 30 * 60 * 1_000;
    //private readonly timeToWarn: number = (24*60-1) * 60 * 1_000;// 1 minute after sign on
    constructor(private messageService: MessageService, private auth0UserService: Auth0UserService) { }

    ngOnInit(): void {
        this.interval = setInterval(() => this.CheckUserSession(), 60 * 1000);        
        sessionStorage.setItem('session-warning-popup', 'false');
        //remove the popup blocker once in 5 minutes
        this.intervalClearBlock = setInterval(
            () => {
                this.messageService.clear('brownWarningPopupWithOk');
                sessionStorage.setItem('session-warning-popup', 'false');
            }, 300*1000);
    }


    async CheckUserSession() {
        let timeRemaining = await this.calculateTimeOutDistance();
        if (isNaN(timeRemaining.Distance))
        {
            return;
        }
        if (timeRemaining.Distance <= 0 ) {
            this.disp = 'Your session has expired. Please login again to resume your work.';
            if (sessionStorage.getItem('session-warning-popup') == 'true')
            {
                return;
            }
            sessionStorage.setItem('session-warning-popup', 'true');
            this.messageService.add({ key: 'brownWarningPopupWithOk', 
                sticky: true, 
                severity: 'warn', 
                data: 'sessionExpired',
                summary: 'Your session has expired. Please login again to resume your work.' });
            return;
        }
        else if (timeRemaining.Distance <= this.timeToWarn ) {
            let timeLeftForExpiration = '';
            if (timeRemaining.Hrs > 0){
                timeLeftForExpiration = timeRemaining.Hrs + ' hours ';
            }
            if (timeRemaining.Min > 0){
                timeLeftForExpiration += timeRemaining.Min + ' minutes';
            }           
            this.disp = 'Your session expires in ' + timeLeftForExpiration ;     
            if (sessionStorage.getItem('session-warning-popup') == 'true')
            {
                    return;
            }
            sessionStorage.setItem('session-warning-popup', 'true');
            this.messageService.add({
                key: 'brownWarningPopupWithOk',
                sticky: true,
                severity: 'warn',
                data: 'sessionExpiring',
                summary: 'Your session will expire in ' + timeLeftForExpiration + ', any unsaved data will be lost.  Please save your data, log out after ' + timeLeftForExpiration + ' and login again to resume working.'
            });
        }
    }

    async respondedToSessionTimeout() {
        this.messageService.clear('brownWarningPopupWithOk');
        document.body.style.pointerEvents = "visible";
        let timeRemaining = await this.calculateTimeOutDistance();
        if (timeRemaining.Distance <= 0) {
            if(!!this.auth0UserService?.logOutUrl)
            {
                window.location.href = this.auth0UserService.logOutUrl;
                return;
            }
            var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i];
                var eqPos = cookie.indexOf("=");
                var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                //set expiration on cookie
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
            //also clear session storage
            sessionStorage.clear();
            window.location.href = '/';
        }
    }

    // This method is written for Testing purpose to know the session expiration time
    async calculateTimeOutDistance() {
        var maxExpirationTime: number = this.ExpirationAt;
        var currentTime: number = Date.now();
        var distance: number = maxExpirationTime - currentTime;
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        return {
            Distance: distance,
            Hrs: hours,
            Min: minutes,
            Sec: seconds
        }
    }

    get ExpirationAt(): number {
        
        if(!isNaN(this.auth0UserService?.auth0User?.expireAt))
        {
            return 1_000 * this.auth0UserService.auth0User.expireAt;
        }   
        
        return Number.NaN;
    }

    public ngOnDestroy(): void {
        if (!!!this.interval)
            clearInterval(this.interval);
        if (!!!this.intervalClearBlock)
            clearInterval(this.intervalClearBlock);
    }
}

import { Injectable } from '@angular/core';
import { Settings } from './settings';
import { Observable } from 'rxjs';
import { AppComponent } from '../app.component';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { DAO } from '../modal/DAO';
import { MessageService } from 'primeng/api';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { IAuthenticatedUserInfo, JwtTokenUserInfo } from '../modal/user.models';
import { BrowserDetails } from '../modal/BrowserDetails.Model';
import { AppConfig } from '../app.config';
import { LogLevel } from 'src/app/modal/LogLevel'

@Injectable({
  providedIn: 'any'
})
export class CommonService {

  private pendingRequests: number = 0;
  private accessToken: string = null;
  private jwt: string = null;
  public isAPIErrorPopupDisplayed: boolean = false;
   constructor(
    private httpClient: HttpClient,
    public appComponent: AppComponent,
    public dao: DAO, private appConfig: AppConfig,
    private setting: Settings,
    private messageService: MessageService) {
    this.accessToken = sessionStorage.getItem('salte.auth.access-token');
    this.jwt = sessionStorage.getItem('salte.auth.id-token');
    //Event when message box is closed
    this.messageService.clearObserver.subscribe(x => {
      if (x == null || x == "declinePopup")
        this.isAPIErrorPopupDisplayed = false;
    });

    //Event when message box pops up
    this.messageService.messageObserver.subscribe(x => {
      if (x["key"] == "declinePopup")
        this.isAPIErrorPopupDisplayed = true;
    });
  }



  public get(componentName: string, functionality: string, payload?: any): Observable<any> {

    this.encodeUrlData(payload, 'GET');

    //=====show spinner==========
    this.appComponent.triggerLoader ? this.appComponent.triggerLoader('show') : '';
    this.pendingRequests++;

    let url = this.loadRequestUrl(componentName, functionality, payload);
    let options = this.loadHttpClientRequestOptions()

    return this.httpClient.get(url, { ...options, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.processHttpClientResponse(response, this)),
      catchError((err: HttpErrorResponse) => this.handleError(err, this, url, payload))
    );
  }

  public getDLNJson(): Observable<Response> {
    return this.httpClient.get('assets/DLN.json').pipe(
      map((response: HttpResponse<any>) => this.processHttpClientResponse(response, this)),
      catchError(err => this.handleError(err, this, 'assets/DLN.json'))
    );
  }

  public post(componentName: string, functionality: string, payload?: any): Observable<any> {
    this.encodeUrlData(payload);

    //=====show spinner==========
    this.appComponent.triggerLoader ? this.appComponent.triggerLoader('show') : '';
    this.pendingRequests++;

    let url = this.loadRequestUrl(componentName, functionality, payload);
    let body = payload?.payloadData;
    let options = this.loadHttpClientRequestOptions()

    return this.httpClient.post(url, body, { ...options, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.processHttpClientResponse(response, this)),
      catchError((err: HttpErrorResponse) => this.handleError(err, this, url, payload))
    );
  }

  public put(componentName: string, functionality: string, payload?: any): Observable<any> {

    this.encodeUrlData(payload);

    //=====show spinner==========
    this.appComponent.triggerLoader ? this.appComponent.triggerLoader('show') : '';
    this.pendingRequests++;

    let url = this.loadRequestUrl(componentName, functionality, payload);
    let body = payload?.payloadData;
    let options = this.loadHttpClientRequestOptions();

    return this.httpClient.put(url, body, { ...options, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.processHttpClientResponse(response, this)),
      catchError((err: HttpErrorResponse) => this.handleError(err, this, url, payload))
    );
  }

  public delete(componentName: string, functionality: string, payload?: any): Observable<Response> {

    this.encodeUrlData(payload, 'DELETE');

    //=====show spinner==========
    this.appComponent.triggerLoader ? this.appComponent.triggerLoader('show') : '';
    this.pendingRequests++;

    const url = this.loadRequestUrl(componentName, functionality, payload);
    const options = this.loadHttpClientRequestOptions();

    return this.httpClient.delete(url, { ...options, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.processHttpClientResponse(response, this)),
      catchError((err: HttpErrorResponse) => this.handleError(err, this, url, payload))
    );
  }

  private encodeUrlData(payload?: any, method?: string): void {

    if (method === 'GET' || method === 'DELETE') {
      if (payload?.urlData != undefined) {
        payload.urlData.forEach((element, index) => {
          if (payload.urlData.length < 8) {
            payload.urlData[index] = encodeURIComponent(decodeURIComponent(element));
          }
          else {
            if (element != null && element != false && element != true) {
              if (!element.includes("/")) {
                payload.urlData[index] = encodeURIComponent(decodeURIComponent(element));
              }
              else {
                element = element.replaceAll("/", ".");
                payload.urlData[index] = element;
              }
            }
          }
        });
      }
    }

    else {
      if (payload?.urlData != undefined) {
        payload.urlData.forEach((element, index) => {
          payload.urlData[index] = encodeURIComponent(decodeURIComponent(element));
        });
      }
    }
  }

  private loadRequestUrl(componentName: string, functionality: string, payload?: any): any {
    const settingDetails = this.setting.getBSBPayloadDetails(componentName, functionality, payload.urlData);
    return settingDetails.endPointInfo.endPointUrl;
  }

  private loadHttpClientRequestOptions(): { headers: HttpHeaders } {
    if(this.appConfig.auth0Enabled)
      {
        return {
          headers: new HttpHeaders({
            'authorization': 'Bearer ' + this.dao.auth0User1.accessToken
          })
        };
      }
      else
      {
        return {
          headers: new HttpHeaders({
            'authorization': 'Bearer ' + sessionStorage.getItem('salte.auth.access-token'),
            'authId': sessionStorage.getItem('salte.auth.id-token')
          })
        };
      }
  }

  private handleError(err: HttpErrorResponse, self: any, url: string, payload? : any): Observable<any> {
    self.pendingRequests--;
    //handle your error here
    self.appComponent.triggerLoader ? self.appComponent.triggerLoader('hide') : '';

    if (err.status != 200 && err.status != 401 && !this.isAPIErrorPopupDisplayed) {
      if (err.url.includes('/upload/file')){
        this.messageService.add({ 
          key: 'brownWarningPopupWithOk', 
          sticky: true, 
          severity: 'warn', 
          summary: 'File Upload Error:',
          detail: 'The BSB Portal is experiencing intermittent technology issues. Please come back shortly.'
        });
      }
      else {
        self.appComponent.displayErrorPopupifAnyHitFails ? self.appComponent.displayErrorPopupifAnyHitFails() : '';
      }
    }
    
    //log the error to the DB
    this.logToDB(err, payload);

    return Observable.throwError(err);
  }

  private processHttpClientResponse(response: HttpResponse<any>, self: any): Observable<any> {
    if (response.status != 200 && response.status != 204 && response.status != 401 && !self.isAPIErrorPopupDisplayed && !self.isSessionTimeoutPopupDisplayed) {
      self.appComponent.displayErrorPopupifAnyHitFails ? self.appComponent.displayErrorPopupifAnyHitFails() : '';
    }
    self.pendingRequests--;
    if (self.pendingRequests == 0) {
      if (!!self.appComponent.triggerLoader) {
        self.appComponent.triggerLoader('hide')
      }
    }
    return response.body;
  }

  //Post the webservicecall error to DB with user payload, url and errorMessage
  private logToDB(errorResponse: HttpErrorResponse, userPayload?: any) {
    try {
      let refNumOrDotNum;
      if (!!userPayload?.payloadData?.dotQsnr)
      {
        const qn = userPayload.payloadData.dotQsnr;
        if(qn?.findQuestion != undefined)
        {
          refNumOrDotNum = qn.findQuestion('number')?.answer;
        }
      }
      if(refNumOrDotNum == undefined )
        refNumOrDotNum = userPayload?.payloadData?.number;

      if(refNumOrDotNum == undefined)
        refNumOrDotNum = this.dao.userAgencyCode;

      let payload = {
        email: this.dao.userMail,
        contxtId: refNumOrDotNum,
        browserNameAndVersion: BrowserDetails.getBrowserName(),
        message: `Error: ${errorResponse.message} ${JSON.stringify(errorResponse.error)}, Payload: ${JSON.stringify(userPayload ?? '')}`,
        logLevel: LogLevel.Error,
        methodName: 'CommonService.LogToDB'
      };

      this.encodeUrlData(payload);

      //API does not challenge bear token, but Cloudflare may block it
      let url = this.loadRequestUrl('GlobalEventHandler', 'logWebServiceCallEvents', payload);
      var options = this.loadHttpClientRequestOptions();      
      this.httpClient.post(url, payload, { ...options, observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
        if(!!!response)
        {
          console.error(`no response returned from ${url}`);
        }
        if (response.status > 299) {
          console.error(response);
        }
      }).catch((error: HttpErrorResponse) => {
        console.error(error);
      });
    }
    catch (error) {
      console.error(error);
    }
  }
}

<header class="main_header">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-lg-3 col-md-6 pl-md-0">
                <img style="cursor: pointer;" class="bsb_logo" src="../../../assets/img/BSB_logo.JPG"
                    (click)="GoHome()">
            </div>
            <div class="col-lg-9 float-right p-0 mb-3" *ngIf="auth0Enabled">                
              <app-auth0-login></app-auth0-login>
              <br>
              <div class="col-lg-4 float-right p-0 mb-3 mr-3" *ngIf="!!auth0UserService.auth0User">
                <span class="header_login_res ml-4"
                      *ngIf="dao.PolicySubTemplate != 'InternalAdmin' && auth0UserService.auth0User.isAdmin && !!auth0UserService?.auth0User.isInternal">
                  <a class="addcomm" (click)="redirecToInternalAdmin()">Internal Admin</a>
                </span>
                <span class="header_login_res ml-4" *ngIf="dao?.PolicySubTemplate != 'userManagement' && !!auth0UserService?.auth0User.isAdmin">
                  <a class="addcomm" (click)="RedirecTouserAdmin()">User Admin</a>
                </span>
                <span class="header_login_res ml-0">
                  <a class="addcomm" (click)="GoHome()">Home</a>
                </span>
              </div>
              <div class="col-lg-4 float-right p-0 mb-3 mr-3" *ngIf="auth0LoggedIn">
                <app-session-management ></app-session-management>
              </div>
             
            </div>            
        </div>
    </div>
</header>

<p-toast position="center" key="homeUserAdmin" (onClose)="onReject()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">

                    <button type="button" class="warnBtnSuccess float-right" title="OK" name="btnLHPopUpYes"
                        id="btnLHPopYesID" (click)="confirmNavigate()">
                        <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                        OK
                    </button>
                </div>
                <div class="p-col-6">

                    <button type="button" class="warnBtnerror" title="Cancel" name="btnLHPopUpNo" id="btnLHPopNoID"
                        (click)="onReject()">
                        <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                        CANCEL
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>

<div class="clearfix"></div>

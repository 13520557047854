import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, AuthenticationService } from '@rss/ng-auth-service';
import { AppComponent } from 'src/app/app.component';
import { Settings } from 'src/app/services/settings';
import { DAO } from '../../modal/DAO'
import { AuthenticatedUserComponent } from '../authenticated-user/authenticated-user.component';
import { ChangeDetectionService } from '../../services/changeDetectionService';
import { MessageService } from 'primeng/api';
import { ServiceLayer } from 'src/app/services/serviceLayer';
import { MenuClickContext } from 'src/app/modal/routing.models';
import { CommonService } from 'src/app/services/commonService';
import { EndorsementService } from 'src/app/services/endorsement.service';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { AppConfig } from 'src/app/app.config';
import { Auth0UserService } from 'src/app/services/auth0UserService.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent extends AuthenticatedUserComponent implements OnInit {
  public isRegistrationPage: boolean = false;
  public menuName: string;
  public auth0Enabled: boolean;
  private endorsementRoute: string = '/endorsements/';
  constructor(
    public dao: DAO,
    public appComponent: AppComponent,
    public router: Router,
    public authenticationService: AuthenticationService,
    private setting: Settings,
    public dataChangeService: ChangeDetectionService,
    private messageService: MessageService,
    private serviceLayer: ServiceLayer,
    private endorsementService: EndorsementService,
    private commonService: CommonService,
    private appConfig: AppConfig,
    public auth0UserService: Auth0UserService,) {
    super(authenticationService);
  }

  ngOnInit(): void {
    if (location.href && location.href.split("/") && location.href.split("/").length && location.href.split("/")[location.href.split("/").length - 1] == 'registration') {
      this.isRegistrationPage = true;
    }
    this.auth0Enabled = this.appConfig.auth0Enabled;
      if (this.appConfig.auth0Enabled)
      {
        this.auth0UserService.userLoaded$.subscribe(
          auth0User => {
            if(!!auth0User)
            {
              
            }
          });   
      }
      else
      {
        this.onUserDataLoaded$().pipe(takeUntil(this.unsubscriber)).subscribe(async (hasAuthenticated) => {
          if (hasAuthenticated)
          {  
            this.dao.userAgencyCode = this.userEmail;
            this.dao.userMail = this.userEmail;
            this.dao.userFullName = this.userFullName;
            this.dao.IsInternalUser = this.isInternalUser;
            this.dao.UserRole = this.isAdmin? 'admin':'';
          }
          else
          {
            this.dao.userAgencyCode = '';
            this.dao.userMail = '';
            this.dao.userFullName = '';
            this.dao.IsInternalUser = false;
            this.dao.UserRole = this.isAdmin? 'admin':'';
          }
        });
      }
  }

  GoHome() {
    this.menuName = 'Home';
    
    if (this.router.url.includes(this.endorsementRoute)) {
      this.handleEndorsementNavigation("/home");
      return;
    }
    if (this.detectPageDataChange()) {
      this.serviceLayer.UpdateFiledsValuesAndValidate(this.dao.PolicySubTemplate);
      this.displayPopupIncaseOfUnsavedDataExists();
    }
    else {
      if (!this.isRegistrationPage) {
        this.dao.PolicyTemplate = '';
        this.dao.PolicySubTemplate = 'newsubmission';
        if (window.location.href.indexOf("/home")<0)        
        
        {
          this.router.navigateByUrl('/home');  
        }
      }
    }
  }

  detectPageDataChange(): boolean {
    let menuClickContext: MenuClickContext = new MenuClickContext();
    menuClickContext.currentPageIsValid = true;
    menuClickContext.page = this.menuName;
    this.serviceLayer.menuClickBehavior.next(menuClickContext);
    let hasDataChanged = false;
    //In the future, all pages shall check menuClickContext.currentPageDataChanged
    //but now only UW page checks menuClickContext.currentPageDataChanged
    if (this.dao.PolicyTemplate == "AccountInfo" 
      || this.dao.PolicyTemplate == "uw" 
      || this.dao.PolicyTemplate == "application" 
      || this.dao.PolicyTemplate == "driver" 
      || this.dao.PolicyTemplate == "vehicles" 
      || this.dao.PolicyTemplate == "losshistory") {
      hasDataChanged = menuClickContext.currentPageDataChanged
    }
    else
    {
      hasDataChanged = this.dataChangeService.HasCurrentPolicyTemplateDataChanged();
    }
    return hasDataChanged;
  }

  confirmNavigate() {
    this.messageService.clear();
    document.body.style.pointerEvents = "visible";
    this.dao.PolicyTemplate = '';
    if (this.menuName == 'Home') {
      if (!this.isRegistrationPage) {
        this.dao.PolicyTemplate = '';
        this.dao.PolicySubTemplate = 'newsubmission';
        this.router.navigateByUrl('/home');
      }
    }
    else if (this.menuName == 'UserAdmin') {
      this.router.navigateByUrl('/userManagement');
    }
    else if (this.menuName == 'InternalAdmin') {
      this.router.navigateByUrl('/internalAdmin');
    }
  }

  onReject() {
    this.messageService.clear('homeUserAdmin');
    document.body.style.pointerEvents = "visible";
  }

  displayPopupIncaseOfUnsavedDataExists() {
    this.dao.NotAllowedToSubmit = false;
    this.dao.IsSavedFromMenu = false;
    this.dao.isEmptyListFromMenu = true;
    document.body.style.pointerEvents = "none";
    this.messageService.add({ key: 'homeUserAdmin', sticky: true, severity: 'warn', summary: "If you leave this page without saving, your data will be lost. Are you sure you want to leave this page?" });
  }

  RedirecTouserAdmin() {
    this.menuName = 'UserAdmin';

    if (this.router.url.includes(this.endorsementRoute)) {
      this.handleEndorsementNavigation("/userManagement");
      return;
    }
    if (this.detectPageDataChange()) {
      this.displayPopupIncaseOfUnsavedDataExists();
    }
    else {
      this.router.navigateByUrl('/userManagement');
    }
  }

  Signout() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      //set expiration on cookie
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }

    //also clear session storage
    sessionStorage.clear();
  }
  public login(): void {
    this.appComponent.triggerLoader('show');

    const authConfig: AuthConfig = {
      configId: 'BSB',
      clientId: this.setting.authToken,
      providerUrl: this.setting.providerUrl,
      redirectUrl: this.setting.redirectUrl, //'http://usilg01-ist109:65007/ <-- is Test & local is --> http://localhost:4200/'
      unauthenticatedUrl: this.setting.unauthenticatedUrl,
      unauthorizedRedirect: this.setting.unauthorizedRedirect,
      apiInGateway: false,
      apiBaseUrl: this.setting.apiBaseUrl,  // we'll set this individually for each company
      unprotectedRoutes: [
      ],
    };
    this.authenticationService.login(authConfig, '/home');
    if (this.authenticationService.isAuthenticated)
      this.router.navigateByUrl('/home');
  }
  redirecToInternalAdmin() {
    this.menuName = 'InternalAdmin';

    if (this.router.url.includes(this.endorsementRoute)) {
      this.handleEndorsementNavigation("/internalAdmin");
      return;
    }
    if (this.detectPageDataChange()) {
      this.displayPopupIncaseOfUnsavedDataExists();
    }
    else {
      this.router.navigateByUrl('/internalAdmin');      
    }
  }
  public changePassword(): void {
    const userDetails = {
      givenName: this.userFullName,
      email: this.dao.userMail
    };
    let payload = { "urlData": [], "payloadData": userDetails };
    this.commonService.post('workInProgress', 'changePassword', payload).subscribe((event: any) => {
      if (event) {
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'greenSuccessPopupWithOk', sticky: true, severity: 'success', detail: 'An email with a link to change the password has been sent to User email ID ' + this.dao.userMail + '. The link will expire in 72 hours.' });
      }
    });
  }

  handleEndorsementNavigation(hrefPath: string): void {
    // create MenuClickContext and let endorsement components update currentPageIsValid
    let menuClickContext: MenuClickContext = new MenuClickContext();
    menuClickContext.currentPageIsValid = true;
    menuClickContext.page = this.menuName;
    this.endorsementService.menuClickBehavior.next(menuClickContext);

    // listen for updated menuClickContext from endorsement components and determine how to navigate
    this.endorsementService.menuClickBehavior
    .pipe(debounceTime(100), take(1))
    .subscribe((menuClickContext: MenuClickContext) => {
      if (menuClickContext.currentPageIsValid && !menuClickContext.currentPageDataChanged) {
        this.router.navigateByUrl( hrefPath); 
      } else {
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'homeUserAdmin', sticky: true, severity: 'warn', summary: "If you go back, any data entered will be lost.  Are you sure you would like to go back?" });
      }
    });
  }

  public get auth0LoggedIn(): boolean{
    return !!this.auth0UserService?.auth0User?.expireAt
  }
}

<div class="container">
    <div class="main_content mt-2">
        <span class="text-lg-right float-lg-right">
            <!-- <button pButton type="button" class="cancel_my_sub" (click)="cancelMySubmissionPopUp()" title="Cancel my Submission" label="Cancel my Submission"></button> -->
            <button type="button" class="btnCancelSub" title="Cancel my Submission" (click)="cancelMySubmissionPopUp()"
                name="btnCancelmySubmission" id="btnCancelmySubmissionID">
                <i class="fa fa-times" aria-hidden="true"></i>
                Cancel my Submission
            </button>
        </span>
        <h3>Underwriter Questionnaire</h3>
        <form #UWform="ngForm" style="width:100%;margin-top: 20px;"
            (ngSubmit)="saveAndContinue(UWform)">
            <div class="row" *ngFor="let item of UWList; let i= index; trackBy: trackItem">
                <div class="col-lg-12" *ngIf="item?.parentKey == '' || item?.parentKey == item?.key">
                    <div class="wid_100">
                        <label class="col-lg-12 float-left" name="lbltitle_{{i}}" id="lbltitleID_{{i}}"
                            *ngIf="item.group != 'Group2' || i==CheckboxquesIndex">
                            <div class="col-lg-12" *ngIf="item.group != 'Group2'">
                                <div class=" float-left">{{item?.parentSequence}}.</div>
                                <div [ngClass]="InValidAns && (item?.answer == null || item?.answer == '') ? 'validationred' : ''"
                                    class=" float-left col-lg-11" style="text-align: justify;">{{item?.questionLabel}}
                                    <span style="color: red;" *ngIf="item?.required">*</span>
                                </div>
                            </div>
                            <div class="ml-5 mb-4" *ngIf="i==CheckboxquesIndex">
                                <h5>
                                    Please read each statement below and check the box if the question applies.
                                </h5>
                            </div>
                        </label>
                        <div class="col-lg-11 ml-5 d-flex" *ngIf="item.group == 'Group2'">
                            <div class="float-left mt-1 mr-3">
                                <p-checkbox name="chkGroupName_{{item?.parentSequence}}"
                                    id="chkGroupNameID_{{item?.parentSequence}}" type="checkbox" value="true"
                                    binary="true" [(ngModel)]="item.answer"></p-checkbox>
                            </div>
                            <label class="d-flex " name="lblchild_{{i}}" id="lblChildID_{{i}}">
                                <div style="margin-right: 10px;" class=" float-left">{{item?.parentSequence}}.</div>
                                <div class=" float-left " style="text-align: justify;">{{item?.questionLabel}}
                                    <span style="color: red;" *ngIf="item?.required">*</span>
                                </div>
                            </label>
                        </div>
                        <label class="rulmsgchk" name="lblquestionInfoText_{{item?.parentSequence}}"
                            id="questionInfoTextID_{{item?.parentSequence}}"
                            *ngIf="item.group == 'Group2' && item?.answer == (item?.parentValue == 'Y')">
                            {{item?.questionInfoText}}
                        </label>
                        <div class="col-lg-11" *ngIf="item.group != 'Group2'">
                            <div class=" float-left col-lg-10" style="margin-left: 4%;">
                                <div class="p-field-radiobutton">
                                    <p-radioButton name="radUWYes_{{item?.parentSequence}}"
                                        id="radUWYesID_{{item?.parentSequence}}" (onClick)="ChangeAns($event,i,'Y')"
                                        value="Y" class="radio_button" inputId="Y" [(ngModel)]="item.answer">
                                    </p-radioButton>
                                    <label class="radio_button_lbl" name="radlblYes_{{item?.parentSequence}}"
                                        id="radlblYesID_{{item?.parentSequence}}">Yes</label>
                                    <p-radioButton name="radUWNo_{{item?.parentSequence}}"
                                        id="radUWNoID_{{item?.parentSequence}}" (onClick)="ChangeAns($event,i,'N')"
                                        class="radio_button" value="N" inputId="N" [(ngModel)]="item.answer">
                                    </p-radioButton>
                                    <label class="radio_button_lbl" name="radlblNo_{{item?.parentSequence}}"
                                        id="radlblNoID_{{item?.parentSequence}}">No</label>
                                </div>
                            </div>
                            <label class="rulmsgrad" name="lblanswer_{{i}}" id="lblanswerID_{{i}}"
                                *ngIf="item?.answer == item?.parentValue">
                                {{item?.questionInfoText}}
                            </label>
                            <div *ngIf="item?.answer == 'Y'">

                                <div class="row col-lg-12" *ngFor="let child of UWList; let j= index;">
                                    <div class="col-lg-12"
                                        *ngIf="item.key == child.parentKey && child.enabledOperator == 'EQ' && child.type == 'Text' && item.group != 'Group4'">
                                        <div class="uw_child_qus float-left col-lg-11" style="text-align: justify;">
                                            {{child?.questionLabel}}
                                        </div>
                                        <textarea
                                            class="{{(UWform.submitted || dao?.isSubmitForm) && (child.answer == undefined || child.answer == '') && child?.required ? 'form-control ml-5 validate_error_cls' : 'form-control ml-5'}}"
                                            [required]="child?.required" [(ngModel)]="child.answer" rows="2"
                                            name="childanswer_{{item?.parentSequence}}"
                                            id="childanswerID_{{item?.parentSequence}}" type="text"></textarea>
                                    </div>
                                    <!--cargo-->
                                    <div class="col-lg-12"
                                        *ngIf="(item.key == 'refrigerationTrailersParent' || item.key == 'dumpApplicantParent') && child.enabledOperator == 'EQ' && item.group == 'Group4' ">
                                        <div class="uw_child_qus float-left col-lg-11"
                                            *ngIf="child.parentKey == item.key"
                                            [ngClass]="UWform.submitted && (child?.answer == null || child?.answer == '') ? 'validationred' : ''"
                                            style="text-align: justify;">
                                            {{child?.questionLabel}}
                                            <span style="color: red;" *ngIf="child?.required">*</span>
                                        </div>
                                        <div class=" float-left col-lg-10"
                                            *ngIf="child.parentKey == item.key" style="margin-left: 4%;">
                                            <div class="p-field-radiobutton">
                                                <p-radioButton name="radUWYes_{{child.key}}"
                                                    id="radUWYesID_{{child?.parentSequence}}" required
                                                    (onClick)="ChangeAns($event,j,'Y')" value="Y" class="radio_button"
                                                    inputId="Y" [(ngModel)]="child.answer">
                                                </p-radioButton>
                                                <label class="radio_button_lbl"
                                                    name="radlblYes_{{child?.parentSequence}}"
                                                    id="radlblYesID_{{child?.parentSequence}}">Yes</label>
                                                <p-radioButton name="radUWNo_{{child.key}}"
                                                    id="radUWNoID_{{child?.parentSequence}}" required
                                                    (onClick)="ChangeAns($event,j,'N')" class="radio_button" value="N"
                                                    inputId="N" [(ngModel)]="child.answer">
                                                </p-radioButton>
                                                <label class="radio_button_lbl"
                                                    name="radlblNo_{{child?.parentSequence}}"
                                                    id="radlblNoID_{{child?.parentSequence}}">No</label>
                                            </div>
                                        </div>
                                        <label class="rulmsgrad" name="lblanswer_{{i}}" id="lblanswerID_{{i}}"
                                            *ngIf="child?.answer =='N' && child.parentKey == item.key">
                                            {{child?.questionInfoText}}
                                        </label>
                                    </div>
                                    <!--cargo-->
                                    <!--cargo 36th ques-->
                                    <div class="col-lg-12"
                                        *ngIf="item.key == 'legalLiabilityPropertyParent' && item.enabledOperator == 'EQ' && item.group == 'Group4'">
                                        <div class="uw_child_qus float-left col-lg-11" style="text-align: justify;"
                                            *ngIf="child.key == 'legalLiabilityPropertyChild' && child.enabledOperator == 'EQ' && child.group == 'Group4'">
                                            {{child?.questionLabel}}
                                        </div>
                                    </div>
                                </div>           
                                <div ngModelGroup="terAdd" #childgroup="ngModelGroup"
                                    *ngIf="item.key == 'legalLiabilityPropertyParent' && item.enabledOperator == 'EQ' && item.group == 'Group4'">                                    
                                    <ng-container *ngFor="let ter of cargoUwTerminalAddress; let j = index; trackBy: trackItem">
                                        <div *ngIf="!ter.isDeleted" class="row mb-1 cargo-terminal-address">
                                            <div class="col-lg-1"></div>
                                                <div class="col-lg-4" 
                                                    [ngClass]="InValidAns && (child?.answer == null || child?.answer == '') ? 'validationred' : ''">
                                                    <label *ngIf="j == 0">Terminal Address:</label>
                                                    <span *ngIf="j == 0" style="color: red;">*</span>
                                                </div>
                                                <div class="col-lg-6 row" style="display: flex;">
        
                                                    <ng-container *ngIf="showTerminalName">
                                                        <input type="text"
                                                            class="form-control mb-1 name-of-premises"
                                                            [ngClass]="{ validate_error_cls: (UWform.submitted || dao?.isSubmitForm || IsAddBtnClicked) && (!terminalName.valid) }"
                                                            required
                                                            #terminalName="ngModel"
                                                            name="terminalName_{{j}}"
                                                            placeholder="Name of Premises"
                                                            [(ngModel)]="ter.Name">
        
                                                        <span *ngIf="j != 0">
                                                            <i class="fa fa-trash delete_icon ml-3" title="delete" (click)="removeUwTerminalAddress(j)"></i>
                                                        </span>
                                                    </ng-container>
        
                                                    <input type="text"
                                                        class="form-control mb-1 street-address"
                                                        [ngClass]="{
                                                            validate_error_cls: (UWform.submitted || dao?.isSubmitForm || IsAddBtnClicked) && (!terminalStreet.valid),
                                                            full: j !== 0 && showTerminalName
                                                        }"
                                                        name="terminalStreet_{{j}}"
                                                        required
                                                        #terminalStreet="ngModel"
                                                        id="terminalStreet_{{j}}ID"
                                                        placeholder="Street Address"
                                                        [(ngModel)]="ter.Street">
        
                                                    <ng-container *ngIf="j != 0 && !showTerminalName">
                                                        <span>
                                                            <i class="fa fa-trash delete_icon ml-3" title="delete" (click)="removeUwTerminalAddress(j)"></i>
                                                        </span>
                                                    </ng-container>
        
                                                    <div class="row mb-1">
                                                        <div class="col-lg-4 col-md-4 col-4">
                                                            <input type="text"
                                                                class="{{(UWform.submitted || dao?.isSubmitForm || IsAddBtnClicked) && (!terminalCity.valid) ? 'form-control validate_error_cls' : 'form-control'}}"
                                                                name="terminalCity_{{j}}" required #terminalCity="ngModel"
                                                                id="terminalCity_{{j}}ID" placeholder="City"
                                                                [(ngModel)]="ter.City">
                                                        </div><br />
                                                        <div class="col-lg-4 col-md-4 col-4">
                                                            <div
                                                                class="{{(UWform.submitted || dao?.isSubmitForm || IsAddBtnClicked) &&(!terminalState.valid)  ? 'validateDrodown' : ''}}">
                                                                <p-dropdown placeholder="Select State"
                                                                    [options]="stateSettings.StateQuestionOptions"
                                                                    name="terminalState_{{j}}" required
                                                                    id="terminalState_{{j}}ID" [(ngModel)]="ter.State"
                                                                    #terminalState="ngModel">
                                                                </p-dropdown>
                                                            </div>
                                                        </div><br />
                                                        <div class="col-lg-4 col-md-4 col-4">
                                                            <div
                                                                class="{{(UWform.submitted || dao?.isSubmitForm || IsAddBtnClicked) && (!terminalPostalCode.valid)  ? 'validate_error_cls' : ''}}">
                                                                <p-inputMask [(ngModel)]="ter.PostalCode" mask="99999"
                                                                    name="terminalPostalCode_{{j}}" required
                                                                    #terminalPostalCode="ngModel"
                                                                    id="terminalPostalCode_{{i}}ID" placeholder="Zip">
                                                                </p-inputMask>
                                                            </div><br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-1"></div>
                                        </div>
                                    </ng-container>
                                    <div class="col-lg-12 text-right" *ngIf="terminalAddressCount < 3">
                                        <button type="button" class="addcomm"
                                            (click)="IsAddBtnClicked = true; childgroup.valid && AddAnotherTerminalAddress()"
                                            title="Add Another Terminal Address" name="btnAddAnotherTerminalAddress"
                                            id="btnAddAnotherTerminalAddressID">Add Another Terminal Address</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-0" *ngIf="dao.PolicyTemplate == 'uw'">
                <div class="container">
                    <div class="col-lg-12 p-0 mt-3">
                        <span class="uplod text-lg-left mr-3">
                            <!-- <a class="btn btn-md btn-success pl-4 pr-4" role="button" title="Back"
                                (click)="dao.PolicyTemplate = 'AccountInfo'">Back</a> -->
                            <button type="button" class="btnerror" name="btnUWBack" id="btnUWbackID" title="Back"
                                (click)="onBack('AccountInfo')">
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                Back
                            </button>
                        </span>
                        <span class="text-lg-right float-lg-right"
                            *ngIf="UWList && UWList?.length > 0">
                            <!-- <button title="Save & Continue" pButton type="submit" class="btn btn-success" label="Save & Continue"
                                    data-toggle="modal"></button> -->
                            <button type="submit" class="btnsuccess" name="btnUWSaveContinue" id="btnsaveContinueID"
                                title="Save & Continue" data-toggle="modal">
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                Save & Continue
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-toast key="myKey1" position="top-center"></p-toast>
    <p-toast position="center" key="c" (onClose)="onReject('c')" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{message.summary}}</h4>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <!-- <button type="button" pButton (click)="CancelmySubmission()" label="Yes" class="p-button-success"></button> -->
                        <button type="button" class="warnBtnSuccess float-right" name="btnUWpopYes" id="btnUWpopYesID"
                            title="Yes" (click)="CancelmySubmission()">
                            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                            Yes
                        </button>
                    </div>
                    <div class="p-col-6">
                        <!-- <button type="button" pButton (click)="onReject()" label="No" class="p-button-secondary"></button> -->
                        <button type="button" class="warnBtnerror" title="No" name="btnUWpopNo" id="btnUWpopNoID"
                            (click)="onReject('c')">
                            <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                            No
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
    <p-toast key="uwToastKey" position="top-center"></p-toast>
    <p-toast position="center" key="deleteTerminalAddress" (onClose)="onReject('deleteTerminalAddress')"
        [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{message.summary}}</h4>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <button type="button" pButton pButton name="btnTAconfirmRemoveYes" id="btnTAconfirmRemoveYesID"
                            (click)="confirmRemove();" label="Yes" class="warnPBtnError"></button>
                    </div>
                    <div class="p-col-6">
                        <button type="button" pButton pButton name="btnTAconfirmRemoveNo" id="btnTAconfirmRemoveID"
                            (click)="onReject('deleteTerminalAddress')" label="No" class="warnPBtnSuccess"></button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
</div>

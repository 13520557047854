import { Component, EventEmitter, Input, OnDestroy, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { DAO, emptyModal } from "src/app/modal/DAO";
import { IBasePage } from "src/app/modal/IBasePage.modules";
import { MenuClickContext } from "src/app/modal/routing.models";
import { FileuploadService } from "src/app/services/fileupload.service";
import { ServiceLayer } from "src/app/services/serviceLayer";
import { menuComponent } from '../../sharedComponents/menu/menu.component';
import { Subject, Subscription } from "rxjs";
import { QuestionAnswer, ResponseContainer } from "src/app/modal/questionnaire.models";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import _ from 'lodash';
import { LiabilityAndPhysicalDamageFormGroup } from "./liability-and-physical-damage/LiabilityAndPhysicalDamageFormGroup";
import { FileToUploadFormGroup } from "./file-upload-form/FileToUploadFormGroup";
import { FourYearLossHistoryFormGroup } from "./four-year-loss-history/FourYearLossHistoryFormGroup";

@Component({
    selector: 'app-loss-history-version-2',
    templateUrl: './loss-history-version-2.component.html',
})

export class LossHistoryVersion2Component implements OnInit, OnDestroy, IBasePage {

    @Input() RollbackLossData: EventEmitter<string>;
    protected unsubscriber = new Subject();
    private subscription: Subscription;
    private menuClickSubscription: Subscription;
    private apiCallSubscription: Subscription;
    private readonly toastValidation: string = 'Please fill required fields highlighted in red.';
    /** The parent Loss History Info form group container. */
    public lossInfoFG: FormGroup;
    public initialLossInfoValues: any;
    public fileUploadFG: FileToUploadFormGroup;
    public liabPhysicalFG: LiabilityAndPhysicalDamageFormGroup;
    public fourYearLossHistoryFG: FourYearLossHistoryFormGroup;
    public isSubmitted: boolean = false;
    public largeLossInformation: LargeLoss = new LargeLoss();
    
    constructor
        (
            public serviceLayer: ServiceLayer,
            public dao: DAO,
            private messageService: MessageService,
            public menuComponent: menuComponent,
            public fileuploadService: FileuploadService,
            public fb: FormBuilder
        ) {
        this.lossInfoFG = this.fb.group({}); 
        this.liabPhysicalFG = new LiabilityAndPhysicalDamageFormGroup();
        this.fileUploadFG = new FileToUploadFormGroup(this.fb, this.fileuploadService);
        this.fourYearLossHistoryFG = new FourYearLossHistoryFormGroup(this.fb);
    }

    public ngOnInit(): void {
        this.menuClickSubscription = this.serviceLayer.menuClickBehavior.subscribe(
            (menuClickContext: MenuClickContext) => { this.menuClicked(menuClickContext) }
        );
        this.apiCallSubscription =this.serviceLayer.dotSubmissionDetails.subscribe(
            (dotSubmissionDetails) => { this.initializeData(dotSubmissionDetails); }
        );
    }

    public menuClicked(menuClickContext: MenuClickContext): void {
        if (menuClickContext != null && menuClickContext.page != "losshistory") {
            menuClickContext.currentPageIsValid = this.isPageValid();
            menuClickContext.currentPageDataChanged = !_.isEqual(this.lossInfoFG.value, this.initialLossInfoValues);
        }
    }

    public onRollback(currentTempage: string): void {}

    public isPageValid(): boolean {
        return this.lossInfoFG.valid 
        && this.fileUploadFG.isPageValid()
        && this.isFourYearLossHistoryValid()
    }

    private isFourYearLossHistoryValid(): boolean{
        if(this.lossInfoFG.value?.lastFourYearsLosses == 'No') {
            return true;
        }else if (this.lossInfoFG.value?.lastFourYearsLosses == 'Yes' && this.fourYearLossHistoryFG.lossHistoryFormArray.length > 0 && !this.fourYearLossHistoryFG.newLossHistory?.hasTouched){
            return true;
        }
        return false
    }

    public async initializeData(apiResponse: ResponseContainer): Promise<void> {
        if (!!!apiResponse) return;

        this.largeLossInformation.initializeDataFrom(apiResponse);
        this.liabPhysicalFG.initializeFormControls(apiResponse);
        this.fourYearLossHistoryFG.initializeFormControls(apiResponse);

        const isLargeLossIncurred = new FormControl(this.largeLossInformation.isLargeLossIncurred.answer, this.getRequiredValidator(this.largeLossInformation.isLargeLossIncurred));
        const largeLossCount = new FormControl(this.largeLossInformation.largeLossCount.answer);
        const lastFourYearsLosses = new FormControl(this.largeLossInformation.lastFourYearsLosses.answer, this.getRequiredValidator(this.largeLossInformation.lastFourYearsLosses));
        
        this.lossInfoFG.addControl('isLargeLossIncurred', isLargeLossIncurred);
        this.lossInfoFG.addControl('largeLossCount', largeLossCount);
        this.lossInfoFG.addControl('liabPhysicalGrid', this.liabPhysicalFG);
        this.lossInfoFG.addControl('fourYearLossHistoryGrid', this.fourYearLossHistoryFG?.lossHistoryFormArray);
        this.lossInfoFG.addControl('lastFourYearsLosses', lastFourYearsLosses);
        this.addOrRemoveLargeLossCountRequired();

        // Need to wait for fileUpload to finish populating before getting initial values for determining if any changes have been made
        await this.fileUploadFG.initializeFormControls(apiResponse);
        this.lossInfoFG.addControl('fileUpload', this.fileUploadFG);

        this.initialLossInfoValues = Object.assign(this.lossInfoFG.value);
    }

    public largeLossIncurredChange(): void {
        const isLargeLossIncurred = this.lossInfoFG.get('isLargeLossIncurred') as FormControl;
        const largeLossCount = this.lossInfoFG.get('largeLossCount') as FormControl;
        
        this.largeLossInformation.isLargeLossIncurred.answer = isLargeLossIncurred.value;
        // In PrimeNG v10, radio button with reactive forms does not deselect in HTML automatically. setValue fixes this issue
        isLargeLossIncurred.setValue(isLargeLossIncurred.value, { emitEvent: false });
        
        this.addOrRemoveLargeLossCountRequired();
        if (isLargeLossIncurred.value === 'No') {
            largeLossCount.setValue('CLICK');
        } else if (isLargeLossIncurred.value === 'Yes' && largeLossCount.value !== 'CLICK') {
            largeLossCount.setValue('CLICK');
        }
        this.largeLossInformation.largeLossCount.answer = largeLossCount.value;
    }

    public largeLossCountChange(): void {
        const largeLossCount = this.lossInfoFG.get('largeLossCount') as FormControl;
        this.largeLossInformation.largeLossCount.answer = largeLossCount.value;
    }

    public lastFourYearsLosseChange(): void {
        const lastFourYearsLosses = this.lossInfoFG.get('lastFourYearsLosses') as FormControl;
        this.largeLossInformation.lastFourYearsLosses.answer = lastFourYearsLosses.value;
        lastFourYearsLosses.setValue(lastFourYearsLosses.value, { emitEvent: false });
        if(lastFourYearsLosses.value == "No"){
            this.fourYearLossHistoryFG.lossHistoryList = [];
            this.fourYearLossHistoryFG?.lossHistoryFormArray.clear();
        }
    }

    private getRequiredValidator(control: emptyModal): Validators | null {
        if (control.required) {
            return Validators.required;
        }
        return null;
    }

    public saveAndContinue(): void {
        this.dao.NotAllowedToSubmit = false;
        this.isSubmitted = true;
        if (this.isPageValid()) {
            let hasBusinessInFourthYear: QuestionAnswer = this.dao.DOTList.losssHistoryQuestionnaire.findQuestion("hasBusinessInFourthYear");
            //clear it without creating a new array by setting its length to zero:
            this.dao.DOTList.losssHistoryQuestionnaire.questionAnswer.length = 0;
            this.fourYearLossHistoryFG.SetNumberOfClaimBasedOnPhysicalDamage(this.liabPhysicalFG.lossInfoGrid)
            this.liabPhysicalFG.toQuestionAnswers().forEach(element => this.dao.DOTList.losssHistoryQuestionnaire.questionAnswer.push(element))
            this.fileUploadFG.toQuestionAnswers().forEach(element => this.dao.DOTList.losssHistoryQuestionnaire.questionAnswer.push(element))
            this.largeLossInformation.toQuestionAnswers().forEach(element => this.dao.DOTList.losssHistoryQuestionnaire.questionAnswer.push(element))
            this.fourYearLossHistoryFG.toQuestionAnswers().forEach(element => this.dao.DOTList.losssHistoryQuestionnaire.questionAnswer.push(element))
            if (hasBusinessInFourthYear != null) {
                this.dao.DOTList.losssHistoryQuestionnaire.questionAnswer.push(hasBusinessInFourthYear);
            }

            this.dao.IsVehicleBreadcrumpClicked = false;
            if(this.fileUploadFG.uploadedFiles.length == 0)
            {
                this.messageService.add({ key: 'toast', severity:'warn', life:3000, summary: 'Loss History', detail: "Minimum 3 years of loss runs will be required prior to binding the quote." });
                setTimeout(() => {
                    this.serviceLayer.save();                    
                }, 500);
            }
            else
            {
                this.serviceLayer.save();
            }            
            this.isSubmitted = false;
        }
        else {
            if (!this.dao.IsSavedFromMenu) {
                this.messageService.add({ key: 'toast', severity: 'error', summary: 'Loss History', detail: this.toastValidation });
            } else {
                this.dao.NotAllowedToSubmit = true
            }
        }
    }

    public onBack(page: string): Promise<boolean> {
        this.dao.IsBackButtonClicked = true;
        return this.menuComponent.menuClick(page);
    }

    public CancelmySubmission(): Promise<void> {
        return this.serviceLayer.CancelmySubmission();
    }

    public cancelMySubmissionPopUp(): void {
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'CancelMySubmission', sticky: true, severity: 'warn', summary: 'All of your data will be lost if you cancel this submission. Are you sure you want to continue?' });
    }

    public onReject(type: string): void {
        this.messageService.clear(type);
        document.body.style.pointerEvents = "visible";
    }

    public resetLastFourYearsLosses() {
        const lastFourYearsLosses = this.lossInfoFG.get('lastFourYearsLosses') as FormControl;
        this.largeLossInformation.lastFourYearsLosses.answer = '';
        lastFourYearsLosses.reset();
    }

    public ngOnDestroy(): void {
        if (!!this.subscription)
            this.subscription.unsubscribe();
        this.unsubscriber.next();
        this.unsubscriber.complete();
        if (!!this.menuClickSubscription)
            this.menuClickSubscription.unsubscribe();

        if (!!this.apiCallSubscription) {
            this.apiCallSubscription.unsubscribe();
            this.apiCallSubscription = null;
        }
    }

    /**
     * Add or remove required validator from largeLossCount depending on value 
     * from isLargeLossIncurred and ODM.
     */
    private addOrRemoveLargeLossCountRequired(): void {
        const isLargeLossIncurred = this.lossInfoFG.get('isLargeLossIncurred') as FormControl;
        const largeLossCount = this.lossInfoFG.get('largeLossCount') as FormControl;
    
        if (isLargeLossIncurred.value === 'No') {
          largeLossCount.clearValidators();
        } else if (
          isLargeLossIncurred.value === 'Yes' &&
          this.getRequiredValidator(this.largeLossInformation.largeLossCount)
        ) {
          largeLossCount.setValidators(Validators.required);
        }
    }
}

class LargeLoss {
    public isLargeLossIncurred: emptyModal = new emptyModal();
    public largeLossCount: emptyModal = new emptyModal();
    public lastFourYearsLosses: emptyModal = new emptyModal();

    initializeDataFrom(apiResponse: ResponseContainer): void {
        this.isLargeLossIncurred = emptyModal.initializeFrom(apiResponse.losssHistoryQuestionnaire.findQuestion("liabIsLossIncurred"));
        this.largeLossCount = emptyModal.initializeFrom(apiResponse.losssHistoryQuestionnaire.findQuestion("liabIndividualLosses"));
        this.lastFourYearsLosses = emptyModal.initializeFrom(apiResponse.losssHistoryQuestionnaire.findQuestion("lastFourYearsLosses"))
    }

    toQuestionAnswers(): QuestionAnswer[] {
        let questionAnswers: Array<QuestionAnswer> = [];
        questionAnswers.push(this.isLargeLossIncurred);
        questionAnswers.push(this.largeLossCount);
        questionAnswers.push(this.lastFourYearsLosses);
        return questionAnswers;
    }
}